import React, { FunctionComponent, useState } from 'react';
import { Box } from '@material-ui/core';
import { useCommonStyles } from '../CommonStyles';
import Card from '../UI/Card';
import { cards, CardData, CardTitles } from '../Data/CardData';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHasPremiumPlan } from 'hooks/premiumPlan';
import NotAvailable from '../NotAvailable/NotAvailable';

interface Props {
    title: string;
    category: string;
}

const Sales: FunctionComponent<Props> = ({ title, category }) => {
    const commonClasses = useCommonStyles();
    const hasPremiumPlan = useHasPremiumPlan();

    const [selectedCard, setSelectedCard] = useState<CardData | null>(null);

    const handleCardClick = (card: CardData) => {
        setSelectedCard(card);
    };

    const showDefaultView = () => {
        setSelectedCard(null);
    };

    const renderContent = () => {
        if (!selectedCard) {
            return (
                <Box display="flex" flexWrap="wrap" className={commonClasses.cardsContent}>
                    {cards
                        .filter(card => card.category === category)
                        .map((card, index) => (
                            <Card
                                key={index}
                                title={card.title}
                                subTitle={card.category}
                                description={card.description}
                                premium={card.premium}
                                icon={card.icon}
                                onClick={() => {
                                    handleCardClick(card);
                                }}
                            />
                        ))}
                </Box>
            );
        }

        // Return different components based on selected Card
        switch (selectedCard.title) {
            case CardTitles.SALES_SUMMARY:
                return selectedCard.premium && !hasPremiumPlan ? <NotAvailable /> : <div>Sales Summary Component</div>;
            case CardTitles.SALES_BY_CATEGORY:
                return selectedCard.premium && !hasPremiumPlan ? (
                    <NotAvailable />
                ) : (
                    <div>Sales by Category Component</div>
                );
            case CardTitles.ADD_ONS_INSIGHTS:
                return selectedCard.premium && !hasPremiumPlan ? (
                    <NotAvailable />
                ) : (
                    <div>Add-Ons Insights Component</div>
                );
            case CardTitles.MONTHLY_NET_SALES:
                return selectedCard.premium && !hasPremiumPlan ? (
                    <NotAvailable />
                ) : (
                    <div>Monthly Net Sales Component</div>
                );
            case CardTitles.TRANSACTIONS:
                return selectedCard.premium && !hasPremiumPlan ? <NotAvailable /> : <div>Transactions Component</div>;
            case CardTitles.SERVICES_INSIGHTS:
                return selectedCard.premium && !hasPremiumPlan ? (
                    <NotAvailable />
                ) : (
                    <div>Services Insights Component</div>
                );
            case CardTitles.PRODUCTS_INSIGHTS:
                return selectedCard.premium && !hasPremiumPlan ? (
                    <NotAvailable />
                ) : (
                    <div>Products Insights Component</div>
                );
            case CardTitles.TAX_LIST:
                return selectedCard.premium && !hasPremiumPlan ? <NotAvailable /> : <div>Tax List Component</div>;
            default:
                return null;
        }
    };

    return (
        <Box className={commonClasses.container}>
            <Box className={commonClasses.contentInsights}>
                <Box className={commonClasses.subViewTitle}>
                    {selectedCard && (
                        <FontAwesomeIcon
                            className={commonClasses.backIcon}
                            icon={faArrowLeft}
                            onClick={showDefaultView}
                        />
                    )}
                    <h2 className={commonClasses.titleDefault}>{selectedCard ? selectedCard.title : title}</h2>
                </Box>
                {renderContent()}
            </Box>
        </Box>
    );
};

export default Sales;
