import { BirthdayReport } from 'model/ClientAndPetsReport';

export interface BirthdayReportState {
    pets: Array<BirthdayReport>;
    page?: number | string;
    size?: number | string;
    totalPages?: number | string;
    totalRecords?: number | string;
    status: BirthdayReportStatus;
    loading: boolean;
}

export enum BirthdayReportStatus {
    Initial,
    Getting,
    GettingSuccess,
    Error
}
