import Staff from '../../model/Staff';

export interface StaffState {
    staff: Array<Staff>;
    member?: Staff;
    status: StaffStatus;
    loading: boolean;
    savedStaff?: Staff;
    warnings: Array<string>;
}

export enum StaffStatus {
    Initial,
    Fetching,
    FetchSuccess,
    Getting,
    GetSuccess,
    GettingByEmail,
    GetByEmailSuccess,
    GetByEmailNotFound,
    Saving,
    SaveSuccess,
    SavingDefaultSchedule,
    SaveDefaultScheduleSuccess,
    Deleting,
    DeleteSuccess,
    Activating,
    Deactivating,
    ActivateSuccess,
    DeactivateSuccess,
    Error
}
