import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useEffect, useState } from 'react';
import PetBirthdayDetail from './PetBirthdayDetailTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getPetBirthdayReport } from 'actions/insights/clientsAndPets/petBirthday/PetBirthdayReportActions';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { BirthdayReport, BirthdaysReport } from 'model/ClientAndPetsReport';
import { Pagination, Period } from '@spike/model';
import moment from 'moment-timezone';
import { useTimeZone } from 'hooks';
import { BirthdayReportStatus, BirthdayReportState } from 'reducers/petBirthdayReport/BirthdayReportState';
import Header from '../../components/Header';

interface PetBirthdayProps {
    description?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: 'white',
            height: 'fit-content',

            [theme.breakpoints.up('md')]: {
                //padding: '50px 24px'
            }
        },
        mobileWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24
            }
        }
    })
);

export const PetBirthday: FunctionComponent<PetBirthdayProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const timeZone = useTimeZone();

    const birthdayLoading = useSelector<RootState, boolean>(state => state.birthdayReport.loading);

    const birthdayStatus = useSelector<RootState, BirthdayReportStatus>(state => state.birthdayReport.status);

    const birthdayReport = useSelector<RootState, BirthdayReportState>(state => state.birthdayReport);

    const [period, setPeriod] = useState<Period | null>({
        from: moment().tz(timeZone).startOf('month'),
        to: moment().tz(timeZone).endOf('month')
    });

    const [veccinesReportConverted, setBirthdaysReportConverted] = useState<Array<BirthdayReport> | null>(null);

    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);

    const [pagination, setPagination] = useState<Pagination>({
        page: Number(birthdayReport.page),
        pageSize: Number(birthdayReport.size),
        totalPages: Number(birthdayReport.totalPages),
        totalRecords: Number(birthdayReport.totalRecords)
    });

    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    let url = `/pet_birthdates_export_report?marketplace_id=${apiClientWrapper.marketplaceId}`;
    if (period?.from) url += `&start_date=${period?.from.format('YYYY/MM/DD')}`;
    if (period?.to) url += `&end_date=${period?.to.format('YYYY/MM/DD')}`;

    const exportFile = {
        name: `Pet Birthday Information ${marketplace.basics.businessName}.csv`,
        url: url,
        body: {
            conditions: {
                deceased: false,
                deleted: false,
                active: true
            }
        },
        apiClientWrapper: apiClientWrapper
    };

    const calendar = {
        optionSelected: 'This month'
    };

    useEffect(() => {
        dispatch(
            getPetBirthdayReport(page, pageSize, period?.from.format('YYYY/MM/DD'), period?.to.format('YYYY/MM/DD'))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, page, pageSize]);

    useEffect(() => {
        if (birthdayStatus === BirthdayReportStatus.GettingSuccess) {
            setBirthdaysReportConverted(birthdayReport.pets);
            setPagination({
                page: Number(birthdayReport.page),
                pageSize: Number(birthdayReport.size),
                totalPages: Number(birthdayReport.totalPages),
                totalRecords: Number(birthdayReport.totalRecords)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [birthdayStatus]);

    const handleChangePeriod = (dateFilter: Period | null, allTime: boolean) => {
        if (allTime) {
            setPeriod(null);
        } else {
            setPeriod({
                from: dateFilter?.from || moment(),
                to: dateFilter?.to || moment()
            });
        }
    };

    const changePageHandler = (page: number) => {
        setPage(page);
    };

    const changePageSizeHandler = (pageSize: number) => {
        setPageSize(pageSize);
        setPage(1);
    };

    return (
        <Box className={classes.container}>
            <Box>
                <Typography color="textSecondary"> {props.description} </Typography>
            </Box>
            <Box className={classes.mobileWrapper}>
                <Header
                    period={period}
                    onChangePeriod={handleChangePeriod}
                    exportFile={exportFile}
                    calendar={calendar}
                />
            </Box>
            <PetBirthdayDetail
                loading={birthdayLoading}
                clients={veccinesReportConverted}
                pagination={pagination}
                changePageHandler={changePageHandler}
                changePageSizeHandler={changePageSizeHandler}
            />
        </Box>
    );
};

export default PetBirthday;
