import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

const NotAvailable: React.FC = () => {
    return (
        <Box>
            <Typography color="textSecondary">No reports available for your subscription plan</Typography>
        </Box>
    );
};

export default NotAvailable;
