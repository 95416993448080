import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { Location } from 'history';
import { LogoC } from 'components/UI';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { FunctionComponent, useEffect, useState } from 'react';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import { useSidebarFooterLinks, useSidebarLinks } from 'hooks/useMenu';
import { reduceResolution, sidebarWidth, wbp } from 'Theme';
import SidebarButton from './SidebarButton';
import { MenuLink } from 'model/Menu';
import { RootState } from 'store';
import clsx from 'clsx';

interface SidebarProps {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'zIndex': 1010,
            'height': '100%',
            'display': 'none',
            'alignItems': 'center',
            'flexDirection': 'column',
            'overflowY': 'scroll',
            'overflowX': 'hidden',
            'paddingBottom': 20,
            'borderRight': '1px solid #DDDDDD',
            'backgroundColor': '#F4F3F0',

            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: `${reduceResolution(sidebarWidth)}px`,
                minWidth: `${reduceResolution(sidebarWidth)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: `${sidebarWidth}px`,
                minWidth: `${sidebarWidth}px`
            },

            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                flex: '0 0 59px'
            },
            [theme.breakpoints.up(wbp)]: {
                flex: '0 0 74px'
            }
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: '17px'
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '21px'
            }
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderTop: '1px solid #DDDDDD'
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: '17px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '21px'
            }
        }
    })
);

const createButton = (item: MenuLink, location: Location, className: string, disabled: boolean) => {
    return (
        <Link to={disabled ? '#' : item.path}>
            <SidebarButton
                key={item.id}
                icon={item.icon}
                label={item.label}
                selected={location.pathname === item.path}
                className={className}
                disabled={disabled}
            />
        </Link>
    );
};

export const Sidebar: FunctionComponent<SidebarProps> = props => {
    const classes = useStyles();

    const location = useLocation();
    const links = useSidebarLinks();
    const footerLinks = useSidebarFooterLinks();

    const { onboardingSteps } = useSelector<RootState, OnboardingStepsState>(state => state.onboardingSteps);

    const [menuDisabled, setMenuDisabled] = useState(false);

    const { businessHoursDone, teamDone, servicesDone, petTypesDone, clientsDone, bookingDone } = onboardingSteps;

    /* 
	//Legacy code to verify if the onboarding is completed
	const verifyOnBoardingProgress = () => {
		const isOnBoardingCompleted =
			businessHoursDone &&
			teamDone &&
			petTypesDone &&
			servicesDone &&
			clientsDone &&
			bookingDone;
		isOnBoardingCompleted ? setMenuDisabled(false) : setMenuDisabled(true);
	};
	*/

    const verifyOnBoardingProgress = () => {
        setMenuDisabled(false);
    };

    useEffect(() => {
        verifyOnBoardingProgress();
        // eslint-disable-next-line
    }, []);

    useNonInitialEffect(() => {
        verifyOnBoardingProgress();
    }, [onboardingSteps]);

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.header}>
                <LogoC />
            </Box>
            <Box className={classes.body}>
                {links.map(item => createButton(item, location, classes.button, menuDisabled))}
            </Box>
            <Box className={classes.footer}>
                {footerLinks.map(item => createButton(item, location, classes.button, menuDisabled))}
            </Box>
        </Box>
    );
};

export default Sidebar;
