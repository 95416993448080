import { Reducer } from 'redux';
import { ClientInsightsReportState, ClientInsightReportStatus } from './ClientInsightsReportState';
import {
    CLIENT_INSIGHT_REPORT_GET_START,
    CLIENT_INSIGHT_REPORT_GET_SUCCESS,
    ClientInsightsReportAction
} from 'actions/insights/clientsAndPets/clientInsights/ClientsInsightsReportActionsTypes';

const initialState: ClientInsightsReportState = {
    pets: [],
    status: ClientInsightReportStatus.Initial,
    loading: false
};

export const ClientInsightsReportReducer: Reducer<ClientInsightsReportState, ClientInsightsReportAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case CLIENT_INSIGHT_REPORT_GET_START:
            return {
                ...state,
                status: ClientInsightReportStatus.Getting,
                loading: true
            };
        case CLIENT_INSIGHT_REPORT_GET_SUCCESS:
            return {
                ...state,
                pets: action.payload.pets,
                page: action.payload.page,
                size: action.payload.size,
                totalPages: action.payload.totalPages,
                totalRecords: action.payload.totalRecords,
                status: ClientInsightReportStatus.GettingSuccess,
                loading: false
            };
        default:
            return state;
    }
};
