import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import OverFullWindowHeader from './OverFullWindowHeader';
import { PropsWithChildren } from 'react';
import OverFullWindowFooter from './OverFullWindowFooter';

interface OverFullWindowProps extends PropsWithChildren {
    hideHeader?: boolean;
    showFooter?: boolean;
    title?: string;
    onClose?: () => void;
    onBack?: () => void;
    onSave?: () => void;
    centered?: boolean;
    className?: string;
    headerClassName?: string;
    footerClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            background: 'white',
            zIndex: 10001,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        detailContainer: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: 1,
            width: '100%',
            background: 'white',
            overflowY: 'auto',
            overflowX: 'hidden',
            top: 0,
            justifyContent: (props: OverFullWindowProps) => (props.centered ? 'center' : 'start')
        }
    })
);

export const OverFullWindow: FunctionComponent<OverFullWindowProps> = props => {
    const classes = useStyles(props);

    return (
        <Box className={clsx(classes.container, props.className)}>
            {!props.hideHeader && (
                <OverFullWindowHeader
                    title={props.title}
                    onClose={props.onClose}
                    className={props.headerClassName}
                />
            )}
            <Box className={classes.detailContainer}>{props.children}</Box>
            {props.showFooter && (
                <OverFullWindowFooter
                    onSave={props.onSave}
                    onBack={props.onBack}
                    className={props.footerClassName}
                />
            )}
        </Box>
    );
};

export default OverFullWindow;
