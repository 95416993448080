import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useRef, useState } from 'react';
import { CalendarFilter } from 'components/UI';
import { Period } from '@spike/model';
import ExportCSV from 'components/UI/ExportCSV/ExportCSV';
import { useNonInitialEffect } from '@versiondos/hooks';
import ApiClient from 'api';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { showErrorThunk } from '@spike/notifications-action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { ApiClientWrapper } from '@spike/api-client';

interface Body {
    conditions: Conditions;
}

interface Conditions {
    deceased: boolean;
    deleted: boolean;
    active: boolean;
}

interface HeaderProps {
    period: Period | null;
    className?: string;
    onChangePeriod: (period: Period | null, allTime: boolean) => void;
    exportFile: {
        name: string;
        url: string;
        body?: Body;
        apiClientWrapper: ApiClientWrapper;
    };
    calendar: {
        optionSelected: string;
    };
}

const backendFieldSeparator = ';';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            gap: 20,
            display: 'flex',
            borderRadius: 12,
            padding: '12px 16px',
            backgroundColor: '#F1F1F1',
            justifyContent: 'space-between',

            [theme.breakpoints.down('sm')]: {},
            [theme.breakpoints.up('md')]: {
                marginTop: 24,
                padding: '14px 20px'
            }
        },
        calendar: {
            '& > button': {
                'height': 39,
                'borderRadius': 30,
                'color': '#222222',
                'border': '2px solid #222222',
                'backgroundColor': 'white',

                [theme.breakpoints.up('md')]: {
                    height: 43
                },

                '&:hover': {
                    backgroundColor: 'white'
                }
            }
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const apiClientWrapper = useApiClientWrapper();
    const csvLinkRef = useRef<any>(null);

    const [exporting, setExporting] = useState<{
        loading: boolean;
        headers: Array<string>;
        data: Array<Array<string>>;
    }>({ loading: false, headers: [], data: [] });

    useNonInitialEffect(() => {
        if (!exporting.loading) {
            csvLinkRef.current?.export();
        }
    }, [exporting.data]);

    const exportHandler = () => {
        setExporting(prev => ({
            ...prev,
            loading: true,
            headers: [],
            data: []
        }));

        ApiClient.post<Array<string>>(props.exportFile?.url, props.exportFile?.body, apiClientWrapper.requestConfig)
            .then(response => {
                const headers: Array<string> = response.data[0].split(backendFieldSeparator);

                const data: Array<Array<string>> = response.data.slice(1).map(row => row.split(backendFieldSeparator));

                setExporting(prev => ({
                    ...prev,
                    loading: false,
                    headers: [...headers],
                    data: [...data]
                }));
            })
            .catch(() => {
                dispatch(showErrorThunk('Error exporting.'));
                setExporting(prev => ({ ...prev, loading: false }));
            });
    };

    const changePeriodHandler = (period: Period, allTime: boolean) => {
        if (allTime) {
            props.onChangePeriod && props.onChangePeriod(null, true);
        } else {
            props.onChangePeriod &&
                props.onChangePeriod(
                    {
                        from: period.from.clone(),
                        to: period.to.clone()
                    },
                    false
                );
        }
    };

    return (
        <Grid container className={props.className}>
            <Grid container xs={12} className={classes.bar}>
                <CalendarFilter
                    hideUpcoming
                    disableFuture
                    showLabelOnMobile
                    dropdownAlignment="left"
                    period={{
                        from: props.period?.from || moment(),
                        to: props.period?.to || moment()
                    }}
                    optionSelected={props.calendar.optionSelected}
                    onChange={changePeriodHandler}
                    className={classes.calendar}
                />
                <ExportCSV
                    headers={exporting.headers}
                    records={exporting.data}
                    filename={props.exportFile.name}
                    ref={csvLinkRef}
                    exporting={exporting.loading}
                    onClick={exportHandler}
                />
            </Grid>
        </Grid>
    );
};

export default Header;
