import { BirthdayReport, BirthdaysReport } from 'model/ClientAndPetsReport';
import moment from 'moment';
import store from 'store';
import { PetBirthdaysReportDto } from './PetBirthdaysReportDto';

export const convertToBirthdaysReport = (pets: PetBirthdaysReportDto): BirthdaysReport => {
    const timeZone = store.getState().login.auth.timeZone;

    const birthdaysReport: BirthdaysReport = {
        pets: [],
        pagination: {
            page: Number(pets.page),
            pageSize: Number(pets.size),
            totalRecords: pets.total_records,
            totalPages: pets.total_pages
        },
        page: pets.page,
        pageSize: pets.size,
        totalRecords: pets.total_records,
        totalPages: pets.total_pages
    };

    pets.pets?.forEach(pet => {
        const petConverted: BirthdayReport = {
            clientId: pet.customers[0].id,
            clientFirstName: pet.customers[0].first_name,
            clientLastName: pet.customers[0].last_name,
            petId: pet.id,
            petName: pet.name,
            birthdate: pet.birthdate,
            deleted: pet.deleted
        };

        birthdaysReport.pets.push(petConverted);
    });

    return birthdaysReport;
};
