import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 24,
            flex: 1,
            width: '100%',
            display: 'flex',
            overflowY: 'auto',
            overflowX: 'hidden',
            flexDirection: 'column',
            padding: '24px 16px 40px',

            [theme.breakpoints.up('md')]: {
                padding: '24px 30px 40px'
            }
        }
    })
);

export const MultiSlotDrawerBody: React.FC<React.PropsWithChildren> = props => {
    const classes = useStyles();

    return <div className={classes.root}>{props.children}</div>;
};
