import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchPayoutsThunk, getNextPayoutThunk } from '@spike/payments-action';
import clsx from 'clsx';
import { Spinner, PriceFormat } from 'components/UI';
import { useApiClientWrapper, useMasterData } from 'hooks';
import { PaymentsBusinessProfile, PaymentsNextPayout, PaymentsPayout as PayoutModel } from '@spike/payments-model';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import { useCommonStyles } from '../CommonStyles';
import { Header } from '../Header/Header';
import { PageResult } from '@spike/model';
import { wbp, reduceResolution } from 'Theme';

interface NextTransferProps {
    className?: string;
    infoPayout?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#222222',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(16)}px`,
                width: `${reduceResolution(308)}px`,
                height: `${reduceResolution(100)}px`,
                padding: `${reduceResolution(17)}px ${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: '16px',
                width: '308px',
                height: '100px',
                padding: '17px 15px'
            },
            [theme.breakpoints.down('md')]: {
                width: '308px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto',
                padding: '15px',
                borderRadius: '15px'
            }
        },
        loading: {
            backgroundColor: 'rgba(255,255,255,0)'
        },
        header: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        body: {
            display: 'flex',
            width: '100%'
        },
        dateContainer: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#92B4A7',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(5)}px ${reduceResolution(7)}px`,
                borderRadius: `${reduceResolution(4)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '5px 7px',
                borderRadius: '4px'
            }
        },
        date: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: 'white',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: 'white',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px'
            }
        },
        amount: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: 'white',
            letterSpacing: '-0.03em',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(32)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '32px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '32px'
            }
        },
        account: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#7A7A7A',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`,
                marginTop: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px',
                marginTop: '14px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                marginTop: '15px'
            }
        },
        info: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                fontSize: '16px',
                fontWeight: '500'
            }
        }
    })
);

export const NextTransfer: FunctionComponent<NextTransferProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const nextTransfer = useSelector<RootState, PaymentsNextPayout | undefined>(state => state.payments.nextPayout);
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(state => state.payments.status);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getNextPayoutThunk(apiClientWrapper));
    }, []);

    useNonInitialEffect(() => {
        if ([PaymentsStatus.Error, PaymentsStatus.GetNextPayoutSuccess].includes(paymentsStatus)) {
            setLoading(false);
        }
    }, [paymentsStatus]);

    return (
        <Box>
            {/*<Box
                className={clsx(
                    classes.container,
                    { [classes.loading]: loading },
                    props.className
                )}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Box className={classes.header}>
                            <Typography className={classes.title}>
                                Next Transfer
                            </Typography>
                            <Box className={classes.dateContainer}>
                                <Typography className={classes.date}>
                                    {nextTransfer?.date.format(
                                        'MMMM Do, YYYY'
                                    ) || '-'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.body}>
                            <PriceFormat
                                className={classes.amount}
                                price={nextTransfer?.amount || 0}
                            />
                            {props.infoPayout && (
                                <Typography className={classes.info}>
                                    See all transfers in Payouts
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
            </Box>*/}
            {(nextTransfer?.bankAccountNumber || nextTransfer?.bankAccount?.accountNumber) && (
                <Typography className={classes.account}>
                    Linked account ••••••••
                    {nextTransfer?.bankAccountNumber
                        ? nextTransfer.bankAccountNumber.slice(-4)
                        : nextTransfer?.bankAccount?.accountNumber?.slice(-4)}
                </Typography>
            )}
        </Box>
    );
};

export default NextTransfer;
