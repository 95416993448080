import React from 'react';
import Table from '../../../Payments/Table';
import { reduceResolution, wbp } from 'Theme';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCommonStyles as useTableStyles } from '../../../Payments/Table/CommonStyles';
import { ClientReport } from 'model/ClientAndPetsReport';
import moment from 'moment-timezone';
import store from 'store';
import { Pagination } from '@spike/model';

interface ClientDetailTableProps {
    loading: boolean;
    clients?: Array<ClientReport> | null;
    pagination?: Pagination;
    changePageHandler: (pageSize: number) => void;
    changePageSizeHandler: (pageSize: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 8,

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#FAFAFA'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 16,
                margin: '0px -24px'
            }
        },
        header: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(63)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '63px'
            }
        },
        cell: {
            fontWeight: 400,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px'
            }
        },
        cellHeader: {
            padding: '20px 0px'
        },
        pagination: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '30px'
            }
        }
    })
);

export const ClientDetailTable: React.FunctionComponent<ClientDetailTableProps> = props => {
    const classes = useStyles();
    const tableStyles = useTableStyles();
    const timeZone = store.getState().login.auth.timeZone;

    const truncateText = (text: string, limit: number) => {
        if (text === null) {
            return '';
        } else if (text.length > limit) {
            return text.substring(0, limit) + ' ...';
        } else {
            return text;
        }
    };

    const headersTable = (
        <>
            <TableCell className={tableStyles.headerCell}>Created On</TableCell>
            <TableCell className={tableStyles.headerCell}>Client First Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Client Last Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Email</TableCell>
            <TableCell className={tableStyles.headerCell}>Phone Number</TableCell>
            <TableCell className={tableStyles.headerCell}>Address</TableCell>
            <TableCell className={tableStyles.headerCell}>City</TableCell>
            <TableCell className={tableStyles.headerCell}>State</TableCell>
            <TableCell className={tableStyles.headerCell}>Zipcode</TableCell>
            <TableCell className={tableStyles.headerCell}>Pet Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Pet Type</TableCell>
            <TableCell className={tableStyles.headerCell}>Pet Breed</TableCell>
            <TableCell className={tableStyles.headerCell}>Pet Notes</TableCell>
        </>
    );

    const rowsClientDetail = (
        <>
            {props.clients?.map(client => {
                const fullAddress = `${client?.addressLineOne ?? ''} ${client?.addressLineTwo ?? ''}`;
                const truncatedAddress = truncateText(fullAddress, 25);
                return (
                    <>
                        <TableRow>
                            <TableCell className={tableStyles.cell}>
                                {client.createdOn ? moment(client.createdOn).tz(timeZone!).format('MM/DD/YYYY') : null}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>{client.clientFirstName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.clientLastName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.email}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.phoneNumber}</TableCell>
                            <TableCell className={tableStyles.cell}>{truncatedAddress}</TableCell>
                            <TableCell className={tableStyles.cell}>{truncateText(client?.city || '', 25)}</TableCell>
                            <TableCell className={tableStyles.cell}>{truncateText(client?.state || '', 25)}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.zipcode}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.petName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.petTypeName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.petBreedName}</TableCell>
                            <TableCell className={tableStyles.cell}>{truncateText(client?.notes || '', 30)}</TableCell>
                        </TableRow>
                    </>
                );
            })}
        </>
    );

    return (
        <Box className={classes.root}>
            <Table
                headers={headersTable}
                rows={props.clients ? rowsClientDetail : <></>}
                loading={props.loading}
                withoutColor={true}
                pagination={props.pagination}
                onChangePage={props.changePageHandler}
                onChangePageSize={props.changePageSizeHandler}
            />
        </Box>
    );
};

export default ClientDetailTable;
