import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import store from 'store';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
    BIRTHDAY_REPORT_GET_START,
    BIRTHDAY_REPORT_GET_SUCCESS,
    BIRTHDAY_REPORT_GET_ERROR,
    BirthdayReportAction
} from './PetBirthdaysReportActionsTypes';
import { PetBirthdaysReportDto } from './PetBirthdaysReportDto';
import { convertToBirthdaysReport } from './petBirthdayReportConverter';

export const getPetBirthdayReport = (
    page?: string | number,
    size?: string | number,
    start?: string,
    end?: string
): ThunkAction<void, null, null, BirthdayReportAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getBirthdayReportStart());

        const { marketplaceId } = store.getState().login.auth;
        let url = `/pet_birthdates_report?marketplace_id=${marketplaceId}`;

        if (page) url += `&page=${page}`;
        if (size) url += `&size=${size}`;
        if (start) url += `&start_date=${start}`;
        if (end) url += `&end_date=${end}`;

        const body = {
            conditions: {
                deceased: false,
                deleted: false,
                active: true
            }
        };

        try {
            const response: AxiosResponse<PetBirthdaysReportDto> = await ApiClient.post(
                url,
                body,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getBirthdayReportSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error get clients and pets - pet birthdates report.'));
            }
        }
    };
};

const getBirthdayReportStart = (): BirthdayReportAction => ({
    type: BIRTHDAY_REPORT_GET_START
});

const getBirthdayReportSuccess = (pets: PetBirthdaysReportDto): BirthdayReportAction => {
    const birthdaysReportConverted = convertToBirthdaysReport(pets);
    return {
        type: BIRTHDAY_REPORT_GET_SUCCESS,
        payload: {
            pets: birthdaysReportConverted.pets,
            page: birthdaysReportConverted.page,
            size: birthdaysReportConverted.pageSize,
            totalPages: birthdaysReportConverted.totalPages,
            totalRecords: birthdaysReportConverted.totalRecords
        }
    };
};

const error = (): BirthdayReportAction => ({ type: BIRTHDAY_REPORT_GET_ERROR });
