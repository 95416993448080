import { EventInput } from '@fullcalendar/core';
import Appointment, { status } from '@spike/appointment-model';
import { BusinessHours, MarketplaceSchedule, Period, Week } from '@spike/model';
import { StaffSchedule } from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import { TempNewBooking } from './model';
import { BlockCalendar } from '@spike/block-calendar-model';
import isEmpty from 'lodash/isEmpty';
import { EventContentArg } from '@fullcalendar/core';
import EventFullCalendar from './EventFullCalendar';
import BlockEventFullCalendar from './BlockEventFullCalendar';
import TempEventFullCalendar from './TempEventFullCalendar';

export const daysOfWeek = new Map([
    ['monday', 1],
    ['tuesday', 2],
    ['wednesday', 3],
    ['thursday', 4],
    ['friday', 5],
    ['saturday', 6],
    ['sunday', 0]
]);

export const convertBusinessHours = (day: string, businessHours: BusinessHours): EventInput => {
    return {
        daysOfWeek: [daysOfWeek.get(day)],
        startTime: businessHours.open,
        endTime: businessHours.close
    };
};

export const getDateBusinessHours = (date: Moment, schedule: MarketplaceSchedule): Period | null => {
    const day = date.format('YYYY-MM-DD');
    const weekDay = date.format('dddd').toLowerCase();

    const specialHour = schedule.specialHours.find(sh => sh.date === day);
    const businessHours = schedule.week[weekDay as keyof Week];

    if (specialHour) {
        return specialHour.isClosed
            ? null
            : {
                  from: moment.tz(`${day} ${specialHour.businessHours?.open}`, 'YYYY-MM-DD HH:mm', schedule.timeZone),
                  to: moment.tz(`${day} ${specialHour.businessHours?.close}`, 'YYYY-MM-DD HH:mm', schedule.timeZone)
              };
    } else {
        return businessHours === undefined
            ? null
            : {
                  from: moment.tz(`${day} ${businessHours?.open}`, 'YYYY-MM-DD HH:mm', schedule.timeZone),
                  to: moment.tz(`${day} ${businessHours?.close}`, 'YYYY-MM-DD HH:mm', schedule.timeZone)
              };
    }
};

export const getWeekBusinessHours = (sunday: Moment, schedule: MarketplaceSchedule): Array<EventInput> => {
    const weekBusinessHours = Array.from(daysOfWeek.keys()).reduce((map, weekDay) => {
        const date = sunday.clone().add(daysOfWeek.get(weekDay), 'day');
        map.set(daysOfWeek.get(weekDay) ?? -1, getDateBusinessHours(date, schedule));
        return map;
    }, new Map<number, Period | null>());

    return Array.from(weekBusinessHours.keys()).map(key => {
        const period = weekBusinessHours.get(key);
        return {
            daysOfWeek: [key],
            startTime: period?.from.format('HH:mm:ss') ?? '00:00:00',
            endTime: period?.to.format('HH:mm:ss') ?? '00:00:00'
        };
    });
};

export const getResourcePeriod = (date: Moment, schedule: StaffSchedule): Period => {
    const weekDay = date.format('dddd').toLowerCase();

    const customDay = schedule.customDays.find(customDay =>
        customDay.from.clone().startOf('day').isSame(date.clone().startOf('day'))
    );
    const businessHours = schedule.default[weekDay as keyof Week];

    const dayOff = {
        from: date.clone().startOf('day'),
        to: date.clone().startOf('day')
    };

    if (customDay) {
        return customDay.on
            ? {
                  from: customDay.from.clone(),
                  to: customDay.to.clone()
              }
            : dayOff;
    } else if (businessHours === null || businessHours === undefined) {
        return dayOff;
    } else {
        return {
            from: moment(`${date.format('DDMMYYYY')}${businessHours?.open}`, 'DDMMYYYYHH:mm'),
            to: moment(`${date.format('DDMMYYYY')}${businessHours?.close}`, 'DDMMYYYYHH:mm')
        };
    }
};

export const getResourceBusinessHours = (date: Moment, schedule: StaffSchedule): Array<EventInput> => {
    const period = getResourcePeriod(date, schedule);

    return [
        {
            daysOfWeek: [daysOfWeek.get(date.format('dddd').toLowerCase())],
            startTime: period.from.clone().format('HH:mm:ss'),
            endTime: period.to.clone().format('HH:mm:ss')
        }
    ];
};

const camelize = (str: string | undefined) => (str ? str.replace(/\b(\w)/g, s => s.toUpperCase()) : '');

const notDraggableStates = [
    status.CANCELLED,
    status.COMPLETED,
    status.DECLINED,
    status.NO_SHOW,
    status.READY_FOR_CHECK_OUT,
    status.PAYMENT
];

export const convertToEvent = (
    appointment: Appointment,
    timeZone: string,
    format: string,
    multipleBookingsIds: Array<number>
): EventInput => ({
    id: appointment.id?.toString() ?? '0',
    appointmentId: appointment.id,
    appointmentUuid: appointment.uuid,
    bookingId: appointment.bookingId,
    start: appointment.duration.from.clone().toDate(),
    end: appointment.duration.to.clone().toDate(),
    title: `${appointment.pet.name} - ${appointment.services[0].name}`,
    resourceId: appointment.services[0].staff?.id?.toString() ?? '0',
    timeZone: timeZone,
    petId: appointment.pet.id,
    petName: appointment.pet.name,
    petType: appointment.pet.type,
    petBreed: appointment.pet.breed?.name || 'Undefined',
    petHasMedicalConditions: appointment.pet.hasMedicalConditions,
    staffAvatar: appointment.services[0].staff?.avatar,
    staffId: appointment.services[0].staff?.id,
    serviceId: appointment.services[0].id,
    serviceName: appointment.services[0].name,
    client: appointment.customer,
    status: { ...appointment.status },
    from: appointment.duration.from.clone(),
    to: appointment.duration.to.clone(),
    startEditable: !notDraggableStates.includes(appointment.status.id),
    temp: false,
    type: 'appointment',
    recurringUuid: appointment.recurringUuid,
    format: format,
    isMultipleBooking: multipleBookingsIds.includes(appointment.bookingId)
});

export const convertToTempEvent = (tempNewBooking: TempNewBooking, timeZone: string): EventInput => {
    const end = tempNewBooking.at.clone().add(tempNewBooking.durationMinutes, 'minutes');

    return {
        id: 'tmp',
        start: tempNewBooking.at.clone().toDate(),
        end: end.clone().toDate(),
        title: `New Booking`,
        resourceId: tempNewBooking.staffId?.toString() ?? '0',
        timeZone: timeZone,
        from: tempNewBooking.at.clone(),
        to: end.clone(),
        startEditable: false,
        temp: true,
        type: 'temporal'
    };
};

export const convertToBlockCalendarEvent = (blockCalendar: BlockCalendar, timeZone: string): EventInput => ({
    id: `block_${blockCalendar.id?.toString() ?? '0'}`,
    blockId: blockCalendar.id,
    blockUuid: blockCalendar.uuid,
    start: blockCalendar.start.clone().toDate(),
    end: blockCalendar.end.clone().toDate(),
    title: isEmpty(blockCalendar.title) ? 'No Title' : camelize(blockCalendar.title),
    resourceId: blockCalendar.staff.id.toString() ?? '0',
    timeZone: timeZone,
    staffAvatar: blockCalendar.staff.image,
    staffId: blockCalendar.staff.id,
    from: blockCalendar.start.clone(),
    to: blockCalendar.end.clone(),
    recurringUuid: blockCalendar.recurringUuid,
    frequency: blockCalendar.frequency,
    startEditable: true,
    temp: false,
    type: 'block'
});

export const renderEvent = (args: EventContentArg, loadingAppointmentIds?: Array<number>) => {
    switch (args.event.extendedProps.type) {
        case 'temporal':
            return <TempEventFullCalendar {...args} />;
        case 'appointment':
            return (
                <EventFullCalendar
                    {...args}
                    loading={loadingAppointmentIds?.includes(args.event.extendedProps.appointmentId)}
                />
            );
        case 'block':
            return (
                <BlockEventFullCalendar
                    {...args}
                    loading={false}
                />
            );
        default:
            return null;
    }
};
