import { ClientReport } from 'model/ClientAndPetsReport';

export interface ClientReportState {
    pets: Array<ClientReport>;
    page?: number | string;
    size?: number | string;
    totalPages?: number | string;
    totalRecords?: number | string;
    status: ClientReportStatus;
    loading: boolean;
}

export enum ClientReportStatus {
    Initial,
    Getting,
    GettingSuccess,
    Error
}
