import React from 'react';
import {
    faArrowLeft,
    faCalendarCheck,
    faCalendarCirclePlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { BusinessSettingsTitle } from 'components/Marketplace/BusinessSettings/UI';
import { ConfirmDialog } from 'components/UI';
import { CheckboxCard } from 'components/UI/CheckboxCard/CheckboxCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { MarketplaceScheduleType } from '@spike/marketplace-model';
import {
    MarketplaceStatus,
    saveScheduleTypeThunk
} from '@spike/marketplace-action';
import { useApiClientWrapper } from 'hooks';
import useNonInitialEffect from '@versiondos/hooks';
import { ScheduleTypeCalendar } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendar';
import clsx from 'clsx';

export interface ScheduleTypeProps {
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            paddingTop: 19,

            [theme.breakpoints.up('sm')]: {
                paddingTop: 31
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 41
            }
        },
        container: {
            maxWidth: 840,

            [theme.breakpoints.up('md')]: {
                padding: '0px 40px'
            }
        },
        businessSettingsTitle: {
            marginBottom: 12
        },
        description: {
            fontSize: 14,
            lineHeight: 1.4,

            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                paddingRight: 20
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        optionsContainer: {
            gap: 12,
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('md')]: {
                gap: 8,
                flexDirection: 'column'
            }
        },
        separator: {
            fontSize: 18,
            fontWeight: 500
        },
        confirmDialog: {
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 455
            },
            '& .MuiDialogActions-root': {
                '& button': {
                    'margin': 0,
                    'height': 47,
                    'width': '100%',

                    '&:last-child': {
                        backgroundColor: '#92B4A7 !important'
                    }
                }
            }
        },
        confirmDialogText: {
            padding: '0px 32px'
        }
    })
);

export const ScheduleType: React.FC<ScheduleTypeProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const marketplaceState = useSelector(
        (state: RootState) => state.marketplace
    );

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] =
        React.useState<boolean>(false);

    const { scheduleType, isWorkSchedule, isMultiSlots } = React.useMemo(
        () => ({
            scheduleType: marketplaceState.marketplace.scheduleType,
            isWorkSchedule:
                marketplaceState.marketplace.scheduleType ===
                MarketplaceScheduleType.Work,
            isMultiSlots:
                marketplaceState.marketplace.scheduleType ===
                MarketplaceScheduleType.MultiSlots
        }),
        [marketplaceState.marketplace.scheduleType]
    );

    const changeScheduleTypeHandler = (option: MarketplaceScheduleType) => {
        if (scheduleType === option) return;

        setShowConfirmDialog(true);
    };

    const toggleScheduleTypeHandler = () => {
        dispatch(
            saveScheduleTypeThunk(
                apiClientWrapper,
                isWorkSchedule
                    ? MarketplaceScheduleType.MultiSlots
                    : MarketplaceScheduleType.Work
            )
        );
    };

    useNonInitialEffect(() => {
        if (marketplaceState.status === MarketplaceStatus.SavingScheduleType) {
            setIsLoading(true);
        }

        if (
            marketplaceState.status ===
            MarketplaceStatus.SaveScheduleTypeSuccess
        ) {
            setIsLoading(false);
            setShowConfirmDialog(false);
        }
    }, [marketplaceState.status]);

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <BusinessSettingsTitle
                    containerClassName={classes.businessSettingsTitle}
                >
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}
                    Schedule Type
                </BusinessSettingsTitle>

                <Typography className={classes.description}>
                    Choose one-on-one scheduling based on each staff&apos;s work
                    hours or slot-based scheduling by drop-off times to tailor
                    your online booking availability and calendar settings.
                </Typography>
            </div>

            <div className={clsx(classes.container, classes.optionsContainer)}>
                <CheckboxCard
                    id="schedule_type_work_schedule"
                    label="By Work Schedule"
                    description="Bookings will be based on each staff member's schedule."
                    icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                    checked={isWorkSchedule}
                    onChange={() =>
                        changeScheduleTypeHandler(MarketplaceScheduleType.Work)
                    }
                />

                <span className={classes.separator}>or</span>

                <CheckboxCard
                    id="schedule_type_multi_slots"
                    label="By Multi-Slots"
                    description="Select multiple time slots and set pet limits per staff."
                    icon={<FontAwesomeIcon icon={faCalendarCirclePlus} />}
                    checked={isMultiSlots}
                    onChange={() =>
                        changeScheduleTypeHandler(
                            MarketplaceScheduleType.MultiSlots
                        )
                    }
                />
            </div>

            {isMultiSlots && <ScheduleTypeCalendar />}

            <ConfirmDialog
                id="schedule_type_confirm_dialog_"
                processing={isLoading}
                open={showConfirmDialog}
                className={classes.confirmDialog}
                title={<p>Change Schedule Type</p>}
                question={
                    <p className={classes.confirmDialogText}>
                        Are you sure you want to change the booking type to{' '}
                        <b>
                            {isWorkSchedule ? 'Multi-Slots' : 'Work Schedule'}
                        </b>
                        ?
                    </p>
                }
                cancelButtonLabel="No"
                confirmButtonLabel="Yes"
                onCancel={() => setShowConfirmDialog(false)}
                onConfirm={toggleScheduleTypeHandler}
            />
        </div>
    );
};
