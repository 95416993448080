import { Reducer } from 'redux';
import { ClientReportState, ClientReportStatus } from './ClientReportState';
import {
    CLIENT_REPORT_GET_ERROR,
    CLIENT_REPORT_GET_START,
    CLIENT_REPORT_GET_SUCCESS,
    ClientReportAction
} from '../../actions/insights/clientsAndPets/clientList/ClientsReportActionsTypes';

const initialState: ClientReportState = {
    pets: [],
    status: ClientReportStatus.Initial,
    loading: false
};

export const ClientReportReducer: Reducer<ClientReportState, ClientReportAction> = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_REPORT_GET_START:
            return {
                ...state,
                status: ClientReportStatus.Getting,
                loading: true
            };
        case CLIENT_REPORT_GET_SUCCESS:
            return {
                ...state,
                pets: action.payload.pets,
                page: action.payload.page,
                size: action.payload.size,
                totalPages: action.payload.totalPages,
                totalRecords: action.payload.totalRecords,
                status: ClientReportStatus.GettingSuccess,
                loading: false
            };
        default:
            return state;
    }
};
