import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useEffect, useState } from 'react';
import ClientInsightsTable from './ClientInsightsDetailTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { InsightReport, InsightsReport } from 'model/ClientAndPetsReport';
import { Pagination, Period } from '@spike/model';
import moment from 'moment-timezone';
import { useTimeZone } from 'hooks';
import {
    ClientInsightReportStatus,
    ClientInsightsReportState
} from 'reducers/clientInsightsReport/ClientInsightsReportState';
import Header from '../../components/Header';
import { getClientInsightsReport } from 'actions/insights/clientsAndPets/clientInsights/ClientInsightsReportActions';

interface ClientInsightsProps {
    description?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: 'white',
            height: 'fit-content',

            [theme.breakpoints.up('md')]: {
                //padding: '50px 24px'
            }
        },
        mobileWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24
            }
        }
    })
);

export const ClientInsights: FunctionComponent<ClientInsightsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const timeZone = useTimeZone();

    const clientLoading = useSelector<RootState, boolean>(state => state.insightReport.loading);

    const clientStatus = useSelector<RootState, ClientInsightReportStatus>(state => state.insightReport.status);

    const insightReport = useSelector<RootState, ClientInsightsReportState>(state => state.insightReport);

    const [period, setPeriod] = useState<Period | null>({
        from: moment().tz(timeZone),
        to: moment().tz(timeZone)
    });

    const [clientsReportConverted, setClientsReportConverted] = useState<Array<InsightReport> | null>(null);

    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);

    const [pagination, setPagination] = useState<Pagination>({
        page: Number(insightReport.page),
        pageSize: Number(insightReport.size),
        totalPages: Number(insightReport.totalPages),
        totalRecords: Number(insightReport.totalRecords)
    });

    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    const exportFile = {
        name: `Client Insights Information ${marketplace.basics.businessName}.csv`,
        url: `/client_insights_export_report?marketplace_id=${apiClientWrapper.marketplaceId}`,
        apiClientWrapper: apiClientWrapper
    };

    const calendar = {
        optionSelected: 'Today'
    };

    useEffect(() => {
        dispatch(
            getClientInsightsReport(page, pageSize, period?.from.format('YYYY/MM/DD'), period?.to.format('YYYY/MM/DD'))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, page, pageSize]);

    useEffect(() => {
        if (clientStatus === ClientInsightReportStatus.GettingSuccess) {
            setClientsReportConverted(insightReport.pets);
            setPagination({
                page: Number(insightReport.page),
                pageSize: Number(insightReport.size),
                totalPages: Number(insightReport.totalPages),
                totalRecords: Number(insightReport.totalRecords)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientStatus]);

    const handleChangePeriod = (dateFilter: Period | null, allTime: boolean) => {
        if (allTime) {
            setPeriod(null);
        } else {
            setPeriod({
                from: dateFilter?.from || moment(),
                to: dateFilter?.to || moment()
            });
        }
    };

    const changePageHandler = (page: number) => {
        setPage(page);
    };

    const changePageSizeHandler = (pageSize: number) => {
        setPageSize(pageSize);
        setPage(1);
    };

    return (
        <Box className={classes.container}>
            <Box>
                <Typography color="textSecondary"> {props.description} </Typography>
            </Box>
            <Box className={classes.mobileWrapper}>
                <Header
                    period={period}
                    onChangePeriod={handleChangePeriod}
                    exportFile={exportFile}
                    calendar={calendar}
                />
            </Box>
            <ClientInsightsTable
                loading={clientLoading}
                clients={clientsReportConverted}
                pagination={pagination}
                changePageHandler={changePageHandler}
                changePageSizeHandler={changePageSizeHandler}
            />
        </Box>
    );
};

export default ClientInsights;
