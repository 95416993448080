import { Reducer } from 'redux';
import { VaccineReportState, VaccineReportStatus } from './VaccineReportState';
import {
    VACCINE_REPORT_GET_ERROR,
    VACCINE_REPORT_GET_START,
    VACCINE_REPORT_GET_SUCCESS,
    VaccineReportAction
} from '../../actions/insights/clientsAndPets/vaccineStatus/VaccinesReportActionsTypes';

const initialState: VaccineReportState = {
    pets: [],
    status: VaccineReportStatus.Initial,
    loading: false
};

export const VaccineReportReducer: Reducer<VaccineReportState, VaccineReportAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case VACCINE_REPORT_GET_START:
            return {
                ...state,
                status: VaccineReportStatus.Getting,
                loading: true
            };
        case VACCINE_REPORT_GET_SUCCESS:
            return {
                ...state,
                pets: action.payload.pets,
                page: action.payload.page,
                size: action.payload.size,
                totalPages: action.payload.totalPages,
                totalRecords: action.payload.totalRecords,
                status: VaccineReportStatus.GettingSuccess,
                loading: false
            };
        default:
            return state;
    }
};
