import { faDashboard, faDollarCircle, faUser } from '@fortawesome/pro-regular-svg-icons';

export const Categories = {
    DASHBOARD: 'Dashboard',
    CLIENTS_AND_PETS: 'Clients & Pets',
    SALES: 'Sales'
};

export const CategoryData = [
    {
        category: Categories.DASHBOARD,
        data: [
            {
                icon: faDashboard,
                title: Categories.DASHBOARD,
                key: 'dashboard',
                visible: true
            }
        ]
    },
    {
        category: Categories.CLIENTS_AND_PETS,
        data: [
            {
                icon: faUser,
                title: Categories.CLIENTS_AND_PETS,
                key: 'clients-and-pets',
                visible: true
            }
        ]
    },
    {
        category: Categories.SALES,
        data: [
            {
                icon: faDollarCircle,
                title: Categories.SALES,
                key: 'sales',
                visible: false
            }
        ]
    }
];

export const getCategoryKey = (categoryName: string) => {
    const category = CategoryData.find(cat => cat.category === categoryName);
    if (category && category.data.length > 0) {
        return category.data[0].key;
    }
    //default to insights
    return 'insights';
};
