import Appointment from '@spike/appointment-model';
import Booking from '@spike/booking-model';
import { AppointmentsState, AppointmentsStatus, countRecurrentAppointmentsThunk } from '@spike/bookings-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import CancellationModal from './V2/CancellationModal/CancellationModal';
import { makeStyles, RadioGroup, Theme } from '@material-ui/core';
import { Radio } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMasterData } from 'hooks';

interface PopUpCancelBookingProps {
    loading?: boolean;
    booking?: Booking;
    appointment?: Appointment;
    onClose: () => void;
    onConfirmCancel: (
        bookingId?: number,
        appointmentId?: number,
        sendNotificacion?: boolean,
        cancelationReasonId?: number
    ) => void;
    onConfirmCancelRecurrent: (appointmentId: number, sendNotificacion: boolean, cancelationReasonId?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingLeft: 31,
        marginBottom: 20,
        gap: 10,
        [theme.breakpoints.down('md')]: {}
    },
    radioText: {
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    },
    radioItem: {
        color: '#000'
    },
    radio: {
        'color': '#000',
        'alignItems': 'self-start',
        'gap': 5,
        '& .MuiRadio-colorSecondary': {
            'padding': 0,
            '&.Mui-checked': {
                color: '#222'
            }
        },
        '& .MuiRadio-colorSecondary > .MuiTypography-root:last-child > .MuiTypography-root': {
            paddingTop: 5
        }
    }
}));

export const PopUpCancelBooking: FunctionComponent<PopUpCancelBookingProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const masterdata = useMasterData();

    const appointmentState = useSelector<RootState, AppointmentsState>(state => state.appointments);

    const [appointmentId, setAppointmentId] = useState<number>();
    const [bookingId, setBookingId] = useState<number | undefined>();
    const [remaining, setRemaining] = useState<number>();
    const [showLoading, setShowLoading] = useState<boolean>(props.loading || true);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [selectedReason, setSelectedReason] = useState<number | undefined>();
    const [value, setValue] = useState<string>('only');
    const [sendNotification, setSendNotification] = useState(true);

    useEffect(() => {
        if (props.booking) {
            setAppointmentId(props.booking.appointments[0].id);
            setBookingId(props.booking.id);

            if (props.booking.appointments[0].recurringUuid) {
                dispatch(countRecurrentAppointmentsThunk(apiClientWrapper, props.booking.appointments[0]!.id!));
            } else {
                setShowLoading(false);
            }
        }

        if (props.appointment) {
            setAppointmentId(props.appointment.id);
            if (props.appointment.recurringUuid) {
                dispatch(countRecurrentAppointmentsThunk(apiClientWrapper, props.appointment!.id!));
            } else {
                setShowLoading(false);
            }
        }
    }, []);

    useNonInitialEffect(() => {
        switch (appointmentState.status) {
            case AppointmentsStatus.CountRecurrentSuccess:
                setRemaining(appointmentState.recurrent?.remaining);
                setShowLoading(false);
                setShowOptions(true);
                break;
            default:
                break;
        }
    }, [appointmentState]);

    const confirmCancelHandler = () => {
        if (!showOptions) {
            bookingId
                ? props.onConfirmCancel(bookingId, undefined, sendNotification, selectedReason)
                : props.onConfirmCancel(undefined, appointmentId, sendNotification, selectedReason);
        } else {
            value === 'only'
                ? bookingId
                    ? props.onConfirmCancel(bookingId, undefined, sendNotification, selectedReason)
                    : props.onConfirmCancel(undefined, appointmentId, sendNotification, selectedReason)
                : bookingId
                ? props.onConfirmCancelRecurrent(appointmentId!, sendNotification, selectedReason)
                : props.onConfirmCancelRecurrent(appointmentId!, sendNotification, selectedReason);
        }
    };

    const closeHandler = () => {
        props.onClose();
    };

    const slot = (
        <RadioGroup
            aria-label="appointments"
            value={value}
            name="appointments"
            className={classes.container}
            onChange={(_event, value) => setValue(value)}
        >
            <FormControlLabel
                className={classes.radio}
                value={'only'}
                control={<Radio className={classes.radioItem} />}
                key={'only'}
                label={<Typography className={classes.radioText}>{'Only cancel this appointment'}</Typography>}
            />
            <FormControlLabel
                className={classes.radio}
                value={'all'}
                control={<Radio className={classes.radioItem} />}
                key={'all'}
                label={
                    <Typography
                        className={classes.radioText}
                    >{`Cancel ${remaining} upcoming recurring appointments`}</Typography>
                }
            />
        </RadioGroup>
    );

    const CANCEL_QUESTION = 'Are you sure you want to cancel this appointment?';

    const CANCEL_TITLE = 'Cancel Appointment';

    const CHECKBOX_LABEL = `Send a notification to ${
        props.booking
            ? props.booking.customer.firstName + ' ' + props.booking.customer.lastName
            : props.appointment?.customer.firstName + ' ' + props.appointment?.customer.lastName
    } that their appointment has been canceled.`;

    return (
        <CancellationModal
            loading={showLoading}
            disabled={props.loading || false}
            title={CANCEL_TITLE}
            checkboxLabel={CHECKBOX_LABEL}
            question={CANCEL_QUESTION}
            reasons={masterdata.cancelationReasons}
            isOpen={props.booking !== undefined || props.appointment !== undefined}
            checked={sendNotification}
            slot={showOptions && slot}
            onSelectReason={selectedField => {
                if (selectedField) {
                    setSelectedReason(Number(selectedField.id));
                }
            }}
            onChecked={setSendNotification}
            onClose={closeHandler}
            onConfirm={confirmCancelHandler}
        />
    );
};

export default PopUpCancelBooking;
