import { faDollarCircle, faDollarSign, faUser } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Categories } from './InsightStructure';

export enum CardTitles {
    /*CLIENTS_AND_PETS*/
    CLIENT_LIST = 'Client List',
    VACCINE_STATUS = 'Vaccine Status',
    PET_BIRTHDAY = 'Pet Birthday',
    CLIENT_INSIGHTS = 'Client Insights',
    PET_BREAKDOWN = 'Pet Breakdown',
    /*SALES*/
    SALES_SUMMARY = 'Sales Summary',
    SALES_BY_CATEGORY = 'Sales by Category',
    ADD_ONS_INSIGHTS = 'Add-Ons Insights',
    MONTHLY_NET_SALES = 'Monthly Net Sales',
    TRANSACTIONS = 'Transactions',
    SERVICES_INSIGHTS = 'Services Insights',
    PRODUCTS_INSIGHTS = 'Products Insights',
    TAX_LIST = 'Tax List'
}

export interface CardData {
    title: CardTitles;
    category: string;
    description: string;
    premium: boolean;
    icon: IconDefinition;
    active: boolean;
}

export const cards: CardData[] = [
    {
        title: CardTitles.CLIENT_LIST,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A full list of all clients and pets',
        premium: false,
        icon: faUser,
        active: true
    },
    {
        title: CardTitles.VACCINE_STATUS,
        category: Categories.CLIENTS_AND_PETS,
        description: 'Overview of each pet’s current vaccination records',
        premium: true,
        icon: faUser,
        active: true
    },
    {
        title: CardTitles.PET_BIRTHDAY,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A full list of pet birthdays',
        premium: true,
        icon: faUser,
        active: true
    },
    {
        title: CardTitles.CLIENT_INSIGHTS,
        category: Categories.CLIENTS_AND_PETS,
        description: 'Overview of client activity, behavior, and value within a specific time frame',
        premium: true,
        icon: faUser,
        active: false
    },
    {
        title: CardTitles.PET_BREAKDOWN,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A comprehensive breakdown of all pets',
        premium: true,
        icon: faUser,
        active: false
    },
    {
        title: CardTitles.SALES_SUMMARY,
        category: Categories.SALES,
        description: 'Overview of all sales for a specific time period',
        premium: false,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.SALES_BY_CATEGORY,
        category: Categories.SALES,
        description: 'Sales performance across different service categories and products',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.ADD_ONS_INSIGHTS,
        category: Categories.SALES,
        description: 'Analysis of revenue generated by each add-on',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.MONTHLY_NET_SALES,
        category: Categories.SALES,
        description: 'Net revenue broken down by each month of the year',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.TRANSACTIONS,
        category: Categories.SALES,
        description: 'Detailed breakdown of Sales, Refunds, and Net Payouts',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.SERVICES_INSIGHTS,
        category: Categories.SALES,
        description: 'Analysis of revenue generated by each service',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.PRODUCTS_INSIGHTS,
        category: Categories.SALES,
        description: 'Analysis of revenue generated by each product',
        premium: true,
        icon: faDollarCircle,
        active: false
    },
    {
        title: CardTitles.TAX_LIST,
        category: Categories.SALES,
        description: 'Detailed breakdown of taxes applied to sales transactions',
        premium: true,
        icon: faDollarCircle,
        active: false
    }
];
