import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export interface PetsCountBadgeProps {
    petsCount: number;
    maxPetsCount: number;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'gap': 2,
            'margin': 0,
            'padding': 3,
            'fontSize': 9,
            'lineHeight': 1,
            'fontWeight': 500,
            'marginTop': 2,
            'color': '#000000',
            'borderRadius': 3,
            'display': 'inline-flex',
            'alignItems': 'center',
            'backgroundColor': '#F1F1F1',

            [theme.breakpoints.up('md')]: {
                gap: 4,
                padding: 5,
                fontSize: 12
            },

            '& svg': {
                fontSize: 10,

                [theme.breakpoints.up('md')]: {
                    fontSize: 14
                }
            }
        }
    })
);

export const PetsCountBadge: React.FC<PetsCountBadgeProps> = props => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, props.className)}>
            <FontAwesomeIcon icon={faPaw} />
            {`${props.petsCount}/${props.maxPetsCount}`}
        </div>
    );
};
