import React from 'react';
import Table from '../../../Payments/Table';
import { reduceResolution, wbp } from 'Theme';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCommonStyles as useTableStyles } from '../../../Payments/Table/CommonStyles';
import { VaccineReport } from 'model/ClientAndPetsReport';
import moment from 'moment-timezone';
import store from 'store';
import { Pagination } from '@spike/model';

interface VaccineDetailTableProps {
    loading: boolean;
    clients?: Array<VaccineReport> | null;
    pagination?: Pagination;
    changePageHandler: (pageSize: number) => void;
    changePageSizeHandler: (pageSize: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 8,

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#FAFAFA'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 16,
                margin: '0px -24px'
            }
        },
        header: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(63)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '63px'
            }
        },
        cell: {
            fontWeight: 400,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px'
            }
        },
        cellHeader: {
            padding: '20px 0px'
        },
        pagination: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '30px'
            }
        }
    })
);

export const VaccineDetailTable: React.FunctionComponent<VaccineDetailTableProps> = props => {
    const classes = useStyles();
    const tableStyles = useTableStyles();
    const timeZone = store.getState().login.auth.timeZone;

    const titleize = (text: string) => {
        return text
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const headersTable = (
        <>
            <TableCell className={tableStyles.headerCell}>Client First Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Client Last Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Pet Name</TableCell>
            <TableCell className={tableStyles.headerCell}>Vaccine</TableCell>
            <TableCell className={tableStyles.headerCell}>Expiration</TableCell>
            <TableCell className={tableStyles.headerCell}>Status</TableCell>
        </>
    );

    const rowsClientDetail = (
        <>
            {props.clients?.map(client => {
                return (
                    <>
                        <TableRow key={client.petId}>
                            <TableCell className={tableStyles.cell}>{client.clientFirstName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.clientLastName}</TableCell>
                            <TableCell className={tableStyles.cell}>{client.petName}</TableCell>
                            <TableCell className={tableStyles.cell}>{titleize(client.vaccineName)}</TableCell>
                            <TableCell className={tableStyles.cell}>
                                {client.expirationDate
                                    ? moment(client.expirationDate).tz(timeZone!).format('MM/DD/YYYY')
                                    : null}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>{client.vaccineStatus}</TableCell>
                        </TableRow>
                    </>
                );
            })}
        </>
    );

    return (
        <Box className={classes.root}>
            <Table
                headers={headersTable}
                rows={props.clients ? rowsClientDetail : <></>}
                loading={props.loading}
                withoutColor={true}
                pagination={props.pagination}
                onChangePage={props.changePageHandler}
                onChangePageSize={props.changePageSizeHandler}
            />
        </Box>
    );
};

export default VaccineDetailTable;
