import { Action } from 'redux';
import { BirthdayReport } from 'model/ClientAndPetsReport';

//Action Types
export const BIRTHDAY_REPORT_GET_START = 'BIRTHDAY_REPORT_GET_START';
export const BIRTHDAY_REPORT_GET_SUCCESS = 'BIRTHDAY_REPORT_GET_SUCCESS';
export const BIRTHDAY_REPORT_GET_ERROR = 'BIRTHDAY_REPORT_GET_ERROR';

//Actions

type BirthdayReportGetStartAction = Action<typeof BIRTHDAY_REPORT_GET_START>;

interface BirthdayReportGetSuccessAction extends Action<typeof BIRTHDAY_REPORT_GET_SUCCESS> {
    payload: {
        pets: Array<BirthdayReport>;
        page?: number | string;
        size?: number | string;
        totalPages?: number | string;
        totalRecords?: number | string;
    };
}

type BirthdayReportErrorAction = Action<typeof BIRTHDAY_REPORT_GET_ERROR>;

export type BirthdayReportAction =
    | BirthdayReportGetStartAction
    | BirthdayReportGetSuccessAction
    | BirthdayReportErrorAction;
