import { VaccinesReport, VaccineReport } from 'model/ClientAndPetsReport';
import moment from 'moment';
import store from 'store';
import { VaccinesReportDto } from './VaccinesReportDto';

export const convertToVaccinesReport = (pets: VaccinesReportDto): VaccinesReport => {
    const timeZone = store.getState().login.auth.timeZone;

    const vaccinesReport: VaccinesReport = {
        pets: [],
        pagination: {
            page: Number(pets.page),
            pageSize: Number(pets.size),
            totalRecords: pets.total_records,
            totalPages: pets.total_pages
        },
        page: pets.page,
        pageSize: pets.size,
        totalRecords: pets.total_records,
        totalPages: pets.total_pages
    };

    pets.pets?.forEach(pet => {
        pet.pet_vaccine_expirations.forEach(vaccine => {
            const petConverted: VaccineReport = {
                clientId: pet.customers[0].id,
                clientFirstName: pet.customers[0].first_name,
                clientLastName: pet.customers[0].last_name,
                petId: pet.id,
                petName: pet.name,
                vaccineId: vaccine.id,
                vaccineName: vaccine.vaccine_id,
                expirationDate: vaccine.expiration_date,
                vaccineStatus: pet.pet_vaccine_expirations[0].status
            };

            vaccinesReport.pets.push(petConverted);
        });
    });

    vaccinesReport.pets = vaccinesReport.pets.sort(function (a, b) {
        return moment(a.expirationDate) < moment(b.expirationDate)
            ? 1
            : moment(b.expirationDate) < moment(a.expirationDate)
            ? -1
            : 0;
    });

    return vaccinesReport;
};
