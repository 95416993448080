import { Week } from '@spike/model';
import { StaffSchedule } from 'model/Staff';
import { Moment } from 'moment-timezone';

export const getDefaultScheduleFromDate = (
    schedule: StaffSchedule,
    date: Moment
) => {
    const weekDay = date.clone().format('dddd').toLowerCase() as keyof Week;

    return schedule.default[weekDay] || null;
};

export const getStaffCustomDayFromDate = (
    schedule: StaffSchedule,
    date: Moment
) => {
    return schedule.customDays.find(day => day.from.isSame(date, 'day'));
};
