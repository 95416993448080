import { ClientsReport, ClientReport } from 'model/ClientAndPetsReport';
import moment from 'moment';
import store from 'store';
import { ClientsReportDto } from './ClientsReportDto';

export const convertToClientsReport = (pets: ClientsReportDto): ClientsReport => {
    const timeZone = store.getState().login.auth.timeZone;

    const clientsReport: ClientsReport = {
        pets: [],
        pagination: {
            page: Number(pets.page),
            pageSize: Number(pets.size),
            totalRecords: pets.total_records,
            totalPages: pets.total_pages
        },
        page: pets.page,
        pageSize: pets.size,
        totalRecords: pets.total_records,
        totalPages: pets.total_pages
    };

    pets.pets?.forEach(pet => {
        const petConverted: ClientReport = {
            id: pet?.id,
            createdOn: moment(pet.customers[0].created_at).tz(timeZone!),
            clientId: pet.customers[0].id,
            clientFirstName: pet.customers[0].first_name,
            clientLastName: pet.customers[0].last_name,
            email: pet.customers[0].email,
            phoneNumber: pet.customers[0].phone,
            addressLineOne: pet.customers[0].addresses?.length ? pet.customers[0].addresses[0]?.address_line_one : '',
            addressLineTwo: pet.customers[0].addresses?.length ? pet.customers[0].addresses[0]?.address_line_two : '',
            city: pet.customers[0].addresses?.length ? pet.customers[0].addresses[0]?.city : '',
            state: pet.customers[0].addresses?.length ? pet.customers[0].addresses[0]?.state : '',
            zipcode: pet.customers[0].addresses?.length ? pet.customers[0].addresses[0]?.zipcode : '',
            petName: pet.name,
            petTypeName: pet.pet_type_name,
            petBreedName: pet.pet_breed_name,
            active: pet.active,
            deleted: pet.deleted,
            deceased: pet.deceased,
            notes: pet?.notes?.length > 0 ? pet?.notes?.map(note => note.message).join(', ') : ''
        };

        clientsReport.pets.push(petConverted);
    });

    clientsReport.pets = clientsReport.pets.sort(function (a, b) {
        return moment(a.createdOn) < moment(b.createdOn) ? 1 : moment(b.createdOn) < moment(a.createdOn) ? -1 : 0;
    });

    return clientsReport;
};
