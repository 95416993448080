import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import store from 'store';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
    VACCINE_REPORT_GET_START,
    VACCINE_REPORT_GET_SUCCESS,
    VACCINE_REPORT_GET_ERROR,
    VaccineReportAction
} from './VaccinesReportActionsTypes';
import { VaccinesReportDto } from './VaccinesReportDto';
import { convertToVaccinesReport } from './vaccineReportConverter';

export const getVaccineReport = (
    page?: string | number,
    size?: string | number,
    start?: string,
    end?: string,
    vaccineStatus?: string
): ThunkAction<void, null, null, VaccineReportAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getVaccineReportStart());

        const { marketplaceId } = store.getState().login.auth;
        let url = `/pet_vaccines_report?marketplace_id=${marketplaceId}`;

        if (page) url += `&page=${page}`;
        if (size) url += `&size=${size}`;
        if (start) url += `&start_date=${start}`;
        if (end) url += `&end_date=${end}`;
        if (vaccineStatus) url += `&vaccine_status=${vaccineStatus}`;

        const body = {
            conditions: {
                deceased: false,
                deleted: false,
                active: true
            }
        };

        try {
            const response: AxiosResponse<VaccinesReportDto> = await ApiClient.post(
                url,
                body,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getVaccineReportSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error get clients and pets - vaccine report.'));
            }
        }
    };
};

const getVaccineReportStart = (): VaccineReportAction => ({
    type: VACCINE_REPORT_GET_START
});

const getVaccineReportSuccess = (pets: VaccinesReportDto): VaccineReportAction => {
    const vaccinesReportConverted = convertToVaccinesReport(pets);
    return {
        type: VACCINE_REPORT_GET_SUCCESS,
        payload: {
            pets: vaccinesReportConverted.pets,
            page: vaccinesReportConverted.page,
            size: vaccinesReportConverted.pageSize,
            totalPages: vaccinesReportConverted.totalPages,
            totalRecords: vaccinesReportConverted.totalRecords
        }
    };
};

const error = (): VaccineReportAction => ({ type: VACCINE_REPORT_GET_ERROR });
