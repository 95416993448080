import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { createStyles, makeStyles, Box, Typography } from '@material-ui/core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalModal from '../../UI/Portals';
import { DropdownOption } from '../ui/Dropdown/model';
import { BottomModalMobile } from 'components/UI/Portals/variants';

const useStyles = makeStyles(() =>
    createStyles({
        modalContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 14px'
        },
        modalItem: {
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'center',
            'width': '100%',
            'height': '54px',
            'margin': '2px 0px',
            'borderBottom': '1px solid #D4D4D4',
            'gap': 10,
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        itemLabel: {
            fontSize: '14px',
            fontWeight: 500
        },
        toggleIcon: {
            cursor: 'pointer'
        },
        icon: { height: 17 }
    })
);

interface OptionsModalProps {
    options: Array<DropdownOption>;
    actionComponent?: ReactNode;
    onClick: (optrionId: string) => void;
}

const OptionsModal: FunctionComponent<OptionsModalProps> = props => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModalHandler = () => setIsOpen(!isOpen);

    const selectOptionHandler = (id: string) => {
        props.onClick(id);
        setIsOpen(false);
    };

    const filteredOptions = props.options.filter(option => option.disabled === undefined || option.disabled === false);

    useEffect(() => {
        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            if (isOpen) {
                window.HubSpotConversations.widget.remove();
            } else {
                window.HubSpotConversations.widget.load();
            }
        }
    }, [isOpen]);

    return (
        <>
            {
                <span
                    className={classes.toggleIcon}
                    aria-label="Staff actions"
                    onClick={toggleModalHandler}
                >
                    {props.actionComponent ? props.actionComponent : <FontAwesomeIcon icon={faEllipsis} />}
                </span>
            }
            <PortalModal
                isActive={isOpen}
                mode="mobileModal"
                onClose={() => setIsOpen(false)}
            >
                <BottomModalMobile>
                    <Box className={classes.modalContainer}>
                        {filteredOptions.map((modalItem, index) => {
                            return (
                                <Box
                                    key={index}
                                    className={classes.modalItem}
                                    onClick={() => selectOptionHandler(modalItem.id)}
                                >
                                    <FontAwesomeIcon
                                        icon={modalItem.icon}
                                        className={classes.icon}
                                    />
                                    <Typography className={classes.itemLabel}>{modalItem.title}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </BottomModalMobile>
            </PortalModal>
        </>
    );
};

export default OptionsModal;
