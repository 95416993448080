import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import OverFullWindowStepsHeader from './OverFullWindowStepsHeader';

import { StepsHeaderOrder } from '@spike/masterdata-model';
import { PropsWithChildren } from 'react';

interface OverFullWindowStepsProps extends PropsWithChildren {
    title?: string;
    optionsSteps?: Array<StepsHeaderOrder>;
    className?: string;
    showBackClose: boolean;
    //
    onBack?: () => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            background: 'white',
            zIndex: 10001,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        detailContainer: {
            position: 'relative',
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            background: 'white',
            overflowY: 'auto',
            top: 0
        }
    })
);

export const OverFullWindowSteps: FunctionComponent<OverFullWindowStepsProps> = props => {
    const classes = useStyles(props);

    return (
        <Box className={clsx(classes.container, props.className)}>
            <OverFullWindowStepsHeader
                title={props.title}
                optionsSteps={props.optionsSteps}
                showBackClose={props.showBackClose}
                //
                onBack={props.onBack}
                onClose={props.onClose}
            />
            <Box className={classes.detailContainer}>{props.children}</Box>
        </Box>
    );
};

export default OverFullWindowSteps;
