import { Action } from 'redux';
import { InsightReport } from 'model/ClientAndPetsReport';

//Action Types
export const CLIENT_INSIGHT_REPORT_GET_START = 'CLIENT_INSIGHT_REPORT_GET_START';
export const CLIENT_INSIGHT_REPORT_GET_SUCCESS = 'CLIENT_INSIGHT_REPORT_GET_SUCCESS';
export const CLIENT_INSIGHT_REPORT_GET_ERROR = 'CLIENT_INSIGHT_REPORT_GET_ERROR';

//Actions

type ClientInsightsReportGetStartAction = Action<typeof CLIENT_INSIGHT_REPORT_GET_START>;

interface ClientInsightsReportGetSuccessAction extends Action<typeof CLIENT_INSIGHT_REPORT_GET_SUCCESS> {
    payload: {
        pets: Array<InsightReport>;
        page?: number | string;
        size?: number | string;
        totalPages?: number | string;
        totalRecords?: number | string;
    };
}

type ClientInsightsReportErrorAction = Action<typeof CLIENT_INSIGHT_REPORT_GET_ERROR>;

export type ClientInsightsReportAction =
    | ClientInsightsReportGetStartAction
    | ClientInsightsReportGetSuccessAction
    | ClientInsightsReportErrorAction;
