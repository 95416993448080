import { Reducer } from 'redux';
import { BirthdayReportState, BirthdayReportStatus } from './BirthdayReportState';
import {
    BIRTHDAY_REPORT_GET_ERROR,
    BIRTHDAY_REPORT_GET_START,
    BIRTHDAY_REPORT_GET_SUCCESS,
    BirthdayReportAction
} from '../../actions/insights/clientsAndPets/petBirthday/PetBirthdaysReportActionsTypes';

const initialState: BirthdayReportState = {
    pets: [],
    status: BirthdayReportStatus.Initial,
    loading: false
};

export const BirthdayReportReducer: Reducer<BirthdayReportState, BirthdayReportAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case BIRTHDAY_REPORT_GET_START:
            return {
                ...state,
                status: BirthdayReportStatus.Getting,
                loading: true
            };
        case BIRTHDAY_REPORT_GET_SUCCESS:
            return {
                ...state,
                pets: action.payload.pets,
                page: action.payload.page,
                size: action.payload.size,
                totalPages: action.payload.totalPages,
                totalRecords: action.payload.totalRecords,
                status: BirthdayReportStatus.GettingSuccess,
                loading: false
            };
        default:
            return state;
    }
};
