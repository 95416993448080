import { InsightReport } from 'model/ClientAndPetsReport';

export interface ClientInsightsReportState {
    pets: Array<InsightReport>;
    page?: number | string;
    size?: number | string;
    totalPages?: number | string;
    totalRecords?: number | string;
    status: ClientInsightReportStatus;
    loading: boolean;
}

export enum ClientInsightReportStatus {
    Initial,
    Getting,
    GettingSuccess,
    Error
}
