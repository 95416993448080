import useNonInitialEffect from '@versiondos/hooks';
import { BookingWrapper } from '@spike/booking-model';
import { FunctionComponent, useRef, useState } from 'react';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import moment from 'moment';
import ExportCSV from 'components/UI/ExportCSV/ExportCSV';

interface BookingsExportCSVProps {
    filteredAndSorted: Array<BookingWrapper>;
}

interface ExportingState {
    headers: string[];
    data: string[][];
}

const csvHeaders = [
    'Client Name',
    'Client Email',
    'Pet Name',
    'Breed',
    'Type',
    'Service',
    'Staff',
    'Date Time',
    'Price',
    'Status'
];

const BookingsExportCSV: FunctionComponent<BookingsExportCSVProps> = props => {
    const marketplace = useMarketplace();
    const apiClientWrapper = useApiClientWrapper();

    const [exportingData, setExportingData] = useState<ExportingState>({
        headers: [],
        data: []
    });

    const [loading, setLoading] = useState(false);

    const csvRef = useRef<any>(null);

    useNonInitialEffect(() => {
        if (exportingData.data && exportingData.data.length > 0) {
            setTimeout(() => {
                csvRef.current?.export();
                setLoading(false);
            }, 1000);
        }
    }, [exportingData]);

    const exportHandler = (filteredAndSorted: Array<BookingWrapper>) => {
        setLoading(true);
        const csvData = filteredAndSorted.flatMap(wrapper =>
            wrapper.appointment
                ? [
                      [
                          wrapper.appointment.customer.firstName + ' ' + wrapper.appointment.customer.lastName,
                          wrapper.appointment.customer.email,
                          wrapper.appointment.pet.name,
                          wrapper.appointment.pet.breed?.name || 'Undefined',
                          wrapper.appointment.pet.type.plural,
                          wrapper.appointment.services[0].name,
                          wrapper.appointment.services[0].staff?.firstName +
                              ' ' +
                              wrapper.appointment.services[0].staff?.lastName || '',
                          wrapper.appointment?.duration?.from?.tz(apiClientWrapper.timeZone).format('YYYY/MM/DD HH:mm'),
                          wrapper.appointment.total.toFixed(2).toString(),
                          wrapper.appointment.status.name
                      ]
                  ]
                : wrapper.booking?.appointments && wrapper.booking?.appointments.length > 1
                ? wrapper.booking?.appointments.map(appointment => [
                      appointment.customer.firstName + ' ' + appointment.customer.lastName,
                      appointment.customer.email,
                      appointment.pet.name,
                      appointment.pet.breed?.name || 'Undefined',
                      appointment.pet.type.plural,
                      appointment.services[0].name,
                      appointment.services[0].staff?.firstName + ' ' + appointment.services[0].staff?.lastName || '',
                      appointment.duration.from?.tz(apiClientWrapper.timeZone).format('YYYY/MM/DD HH:mm'),
                      appointment.services[0].price.toFixed(2).toString(),
                      appointment.status.name
                  ])
                : [
                      [
                          wrapper.booking?.customer?.firstName + ' ' + wrapper.booking?.customer?.lastName || '',
                          wrapper.booking?.customer?.email || '',
                          wrapper.booking?.appointments[0]?.pet?.name || '',
                          wrapper.booking?.appointments[0]?.pet?.breed?.name || '',
                          wrapper.booking?.appointments[0]?.pet?.type?.plural || '',
                          wrapper.booking?.appointments[0]?.services[0]?.name || '',
                          wrapper.booking?.appointments[0]?.services[0]?.staff?.firstName +
                              ' ' +
                              wrapper.booking?.appointments[0]?.services[0]?.staff?.lastName || '',
                          wrapper.booking?.bookedAt?.tz(apiClientWrapper.timeZone).format('YYYY/MM/DD HH:mm') || '',
                          wrapper.booking?.appointments[0]?.total.toFixed(2).toString() || '',
                          wrapper.booking?.appointments[0]?.status?.name || ''
                      ]
                  ]
        );

        setExportingData({
            headers: csvHeaders,
            data: csvData
        });
    };

    const getFileName = () => {
        const date = moment().format('YYYYMMDD_HHmm');
        return `Bookings_Export_${marketplace.basics.businessName.replace(/\s+/g, '')}_${date}.csv`;
    };

    return (
        <ExportCSV
            ref={csvRef}
            headers={exportingData.headers}
            records={exportingData.data}
            filename={getFileName()}
            exporting={loading}
            onClick={() => exportHandler(props.filteredAndSorted)}
        />
    );
};

export default BookingsExportCSV;
