import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { showSuccessThunk } from '@spike/notifications-action';
import { saveStaffSlotsThunk } from 'actions/staff/StaffActions';
import { AlertMessage2 } from 'components/AlertMessage2';
import {
    MultiSlotDatePicker,
    MultiSlotDatePickerOption
} from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/DatePicker';
import { MultiSlotDrawerBody } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerBody';
import { MultiSlotDrawerFooter } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerFooter';
import { MultiSlotDrawerHeader } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerHeader';
import { SlotField } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/SlotField';
import { generateHourOptions, getStaffScheduleByWeekDay } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import Button from 'components/UI/V2/Button/Button';
import { Select } from 'components/UI/V2/Forms/Select/Select';
import { useMarketplace, useTimeZone } from 'hooks';
import { WeekDay } from 'model';
import Staff, { StaffDefaultSlot, StaffSlot } from 'model/Staff';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';

export interface DefaultSlotsProps {
    onClose: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        slotsContainer: {
            width: '100%'
        },
        addSlotButton: {
            gap: 6,
            border: 0,
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            display: 'flex',
            alignItems: 'center'
        },
        alertMessage: {
            'margin': 0,

            '& p': {
                fontSize: 16,
                lineHeight: 1.4,
                paddingRight: 10
            },
            '& button': {
                marginTop: 16
            }
        },
        addSlotsToText: {
            fontSize: 16,
            fontWeight: 600
        },
        selectStaff: {
            marginTop: 8,
            marginBottom: 24
        },
        selectStaffOption: {
            'gap': 10,
            'display': 'flex',
            'alignItems': 'center',

            '& img': {
                width: 30,
                aspectRatio: 1,
                borderRadius: '50%'
            }
        }
    })
);

const WEEK_DAY_OPTIONS: MultiSlotDatePickerOption<WeekDay>[] = [
    {
        label: 'Mon',
        value: 'monday'
    },
    {
        label: 'Tue',
        value: 'tuesday'
    },
    {
        label: 'Wed',
        value: 'wednesday'
    },
    {
        label: 'Thu',
        value: 'thursday'
    },
    {
        label: 'Fri',
        value: 'friday'
    },
    {
        label: 'Sat',
        value: 'saturday'
    },
    {
        label: 'Sun',
        value: 'sunday'
    }
];

export const DefaultSlots: React.FC<DefaultSlotsProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const [originalSlots, setOriginalSlots] = React.useState<Array<StaffDefaultSlot>>();
    const [modifiedSlots, setModifiedSlots] = React.useState<Array<StaffDefaultSlot>>();
    const [selectedStaff, setSelectedStaff] = React.useState<Staff>();
    const [selectedWeekDay, setSelectedWeekDay] = React.useState<WeekDay>(WEEK_DAY_OPTIONS[0].value);

    const staffState = useSelector((state: RootState) => state.staff);

    const daySchedule = React.useMemo(() => {
        if (!selectedStaff) return null;

        return getStaffScheduleByWeekDay(marketplace, selectedStaff?.schedule, selectedWeekDay, timeZone);
    }, [selectedWeekDay, selectedStaff]);

    const daySlots: Array<StaffSlot> = React.useMemo(() => {
        const weekDaySlots = modifiedSlots?.filter(slot => slot.weekDay === selectedWeekDay) || [];

        const originalDaySlots = weekDaySlots
            .filter(slot => originalSlots?.some(originalSlot => originalSlot.uuid === slot.uuid))
            .sort((a, b) => a.time.localeCompare(b.time));

        const newSlots = weekDaySlots.filter(
            slot => !originalSlots?.some(originalSlot => originalSlot.uuid === slot.uuid)
        );

        return [...originalDaySlots, ...newSlots];
    }, [modifiedSlots, selectedWeekDay]);

    const hourOptions = React.useMemo(() => {
        if (!daySchedule) return [];

        return generateHourOptions(daySchedule.from, daySchedule.to);
    }, [daySchedule]);

    const selectedDateHours = React.useMemo(() => {
        return daySlots.map(slot => slot.time);
    }, [daySlots]);

    /*
     * Check if the modified slots are different from the original slots
     */
    const isModified = React.useMemo(() => {
        return JSON.stringify(originalSlots) !== JSON.stringify(modifiedSlots);
    }, [originalSlots, modifiedSlots]);

    const selectStaffHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setSelectedStaff(staffState.staff.find(staff => staff.id === Number(event.target.value)));
    };

    const addSlotHandler = () => {
        if (!selectedWeekDay) return;

        let index = 0;
        let selectedTime: string;

        while (index < hourOptions.length) {
            const value = hourOptions[index].value;
            if (!selectedDateHours.includes(value)) {
                selectedTime = value;
                break;
            }
            index++;
        }

        setModifiedSlots(prevSlots => [
            ...(prevSlots || []),
            {
                uuid: uuid(),
                time: selectedTime,
                weekDay: selectedWeekDay,
                petsCount: 1
            }
        ]);
    };

    const changeSlotHandler = (slot: StaffSlot, time?: string, petsCount?: number) => {
        if (!time || !petsCount) return;

        setModifiedSlots(prevSlots => {
            const weekdaySlots = prevSlots?.filter(slot => slot.weekDay === selectedWeekDay) || [];

            return [
                ...(prevSlots?.filter(slot => slot.weekDay !== selectedWeekDay) || []),
                ...weekdaySlots.map(prevSlot => (prevSlot === slot ? { ...prevSlot, time, petsCount } : prevSlot))
            ];
        });
    };

    const deleteSlotHandler = (slot: StaffSlot) => {
        setModifiedSlots(prevSlots => prevSlots?.filter(prevSlot => prevSlot !== slot));
    };

    const saveSlotsHandler = () => {
        if (!selectedStaff || staffState.loading) return;

        /*
         * Remove all custom slots that override the default slots
         */
        const customDaySlots = selectedStaff.slots.customDays.filter(slot => {
            const slotWeekDay = slot.date.format('dddd').toLowerCase();

            return slot.on
                ? true
                : !modifiedSlots?.some(
                      defaultSlot => defaultSlot.weekDay === slotWeekDay && defaultSlot.time === slot.time
                  );
        });

        dispatch(
            saveStaffSlotsThunk(
                {
                    ...selectedStaff,
                    slots: {
                        customDays: customDaySlots,
                        default: modifiedSlots || []
                    }
                },
                () => dispatch(showSuccessThunk('Default slots updated successfully'))
            )
        );
    };

    React.useEffect(() => {
        setOriginalSlots(selectedStaff?.slots.default);
        setModifiedSlots(selectedStaff?.slots.default);
    }, [selectedStaff?.slots.default]);

    React.useEffect(() => {
        if (staffState.status === StaffStatus.SaveSuccess) {
            const updatedStaff = staffState.staff.find(staff => staff.id === selectedStaff?.id);

            if (updatedStaff) {
                setSelectedStaff(updatedStaff);
            }
        }
    }, [staffState.status]);

    return (
        <>
            <MultiSlotDrawerHeader
                title="Setup Default Slots"
                label="Staff"
                description="These slots will repeat every week unless changes are made for specific dates."
                onClose={props.onClose}
            >
                <>
                    <Select<Staff>
                        label="Select Staff"
                        wrapperClassName={classes.selectStaff}
                        renderOption={option => (
                            <div className={classes.selectStaffOption}>
                                <img src={option.data?.person.avatar} />
                                {option.label}
                            </div>
                        )}
                        options={staffState.staff
                            .filter(staff => staff.active)
                            .map(staff => ({
                                value: `${staff.id}`,
                                label: staff.person.firstName,
                                data: staff
                            }))}
                        onChange={selectStaffHandler}
                    />

                    <Typography className={classes.addSlotsToText}>Add Slots To</Typography>
                    <MultiSlotDatePicker<WeekDay>
                        options={WEEK_DAY_OPTIONS}
                        selectedOption={selectedWeekDay}
                        onDateChange={setSelectedWeekDay}
                    />
                </>
            </MultiSlotDrawerHeader>

            <MultiSlotDrawerBody>
                {daySchedule?.isBusinessClosed ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            Your business hours are set to closed for this day. Update your business hours to add time
                            slots.
                        </Typography>

                        <Link
                            to="/business_settings/info/hours"
                            id="add-slot-alert-setup-hours-button"
                        >
                            <Button
                                variant="black"
                                label="Setup Business Hours"
                            />
                        </Link>
                    </AlertMessage2>
                ) : daySchedule?.isStaffAvailable === false ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            This staff member is not scheduled to work on this day. Update their schedule to add time
                            slots.
                        </Typography>

                        <Link
                            to={`/staff#staffId=${selectedStaff?.id}`}
                            id="add-slot-alert-edit-schedule-button"
                        >
                            <Button
                                variant="black"
                                label="Edit Schedule"
                            />
                        </Link>
                    </AlertMessage2>
                ) : (
                    <>
                        {daySlots.length > 0 && (
                            <div className={classes.slotsContainer}>
                                <SlotField showLabel />

                                {daySlots.map((slot, index) => (
                                    <SlotField
                                        key={index}
                                        time={slot.time}
                                        hours={hourOptions}
                                        petsCount={slot.petsCount}
                                        selectedHours={selectedDateHours}
                                        onDelete={() => deleteSlotHandler(slot)}
                                        onChange={(time, petsCount) => changeSlotHandler(slot, time, petsCount)}
                                    />
                                ))}
                            </div>
                        )}

                        <button
                            type="button"
                            className={classes.addSlotButton}
                            id="add-slot-button"
                            onClick={addSlotHandler}
                            disabled={!selectedStaff || selectedDateHours.length >= hourOptions.length}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            Add Slot
                        </button>
                    </>
                )}
            </MultiSlotDrawerBody>

            <MultiSlotDrawerFooter>
                <Button
                    noColorOnDisabled
                    variant="green"
                    label="Save as Default"
                    id="add-slot-save-as-default-button"
                    disabled={!isModified}
                    onClick={saveSlotsHandler}
                />
            </MultiSlotDrawerFooter>
        </>
    );
};
