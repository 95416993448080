import { Action } from 'redux';
import { VaccineReport } from 'model/ClientAndPetsReport';

//Action Types
export const VACCINE_REPORT_GET_START = 'VACCINE_REPORT_GET_START';
export const VACCINE_REPORT_GET_SUCCESS = 'VACCINE_REPORT_GET_SUCCESS';
export const VACCINE_REPORT_GET_ERROR = 'VACCINE_REPORT_GET_ERROR';

//Actions

type VaccineReportGetStartAction = Action<typeof VACCINE_REPORT_GET_START>;

interface VaccineReportGetSuccessAction extends Action<typeof VACCINE_REPORT_GET_SUCCESS> {
    payload: {
        pets: Array<VaccineReport>;
        page?: number | string;
        size?: number | string;
        totalPages?: number | string;
        totalRecords?: number | string;
    };
}

type VaccineReportErrorAction = Action<typeof VACCINE_REPORT_GET_ERROR>;

export type VaccineReportAction =
    | VaccineReportGetStartAction
    | VaccineReportGetSuccessAction
    | VaccineReportErrorAction;
