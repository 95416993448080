import { VaccineReport } from 'model/ClientAndPetsReport';

export interface VaccineReportState {
    pets: Array<VaccineReport>;
    page?: number | string;
    size?: number | string;
    totalPages?: number | string;
    totalRecords?: number | string;
    status: VaccineReportStatus;
    loading: boolean;
}

export enum VaccineReportStatus {
    Initial,
    Getting,
    GettingSuccess,
    Error
}
