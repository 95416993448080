import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import store from 'store';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
    CLIENT_REPORT_GET_START,
    CLIENT_REPORT_GET_SUCCESS,
    CLIENT_REPORT_GET_ERROR,
    ClientReportAction
} from './ClientsReportActionsTypes';
import { ClientsReportDto } from './ClientsReportDto';
import { convertToClientsReport } from './clientReportConverter';

export const getClientReport = (
    page?: string | number,
    size?: string | number,
    start?: string,
    end?: string
): ThunkAction<void, null, null, ClientReportAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getClientReportStart());

        const { marketplaceId } = store.getState().login.auth;
        let url = `/customers_report?marketplace_id=${marketplaceId}`;

        if (page) url += `&page=${page}`;
        if (size) url += `&size=${size}`;
        if (start) url += `&start_date=${start}`;
        if (end) url += `&end_date=${end}`;

        const body = {
            conditions: {
                deceased: false,
                active: true
            }
        };

        try {
            const response: AxiosResponse<ClientsReportDto> = await ApiClient.post(
                url,
                body,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getClientReportSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error get clients and pets - client report.'));
            }
        }
    };
};

const getClientReportStart = (): ClientReportAction => ({
    type: CLIENT_REPORT_GET_START
});

const getClientReportSuccess = (clients: ClientsReportDto): ClientReportAction => {
    const clientsReportConverted = convertToClientsReport(clients);
    return {
        type: CLIENT_REPORT_GET_SUCCESS,
        payload: {
            pets: clientsReportConverted.pets,
            page: clientsReportConverted.page,
            size: clientsReportConverted.pageSize,
            totalPages: clientsReportConverted.totalPages,
            totalRecords: clientsReportConverted.totalRecords
        }
    };
};

const error = (): ClientReportAction => ({ type: CLIENT_REPORT_GET_ERROR });
